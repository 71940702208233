/* eslint-disable max-lines */
/*
|-------------------------------------------------------------------------------
| Dialog Mappings
|-------------------------------------------------------------------------------
|
| Mapping meta information onto the dialogs callName. The callName is the key
| under which the dialog is exposed in the bridge and in `AVM.shell.dialogs`!
| The following meta information is currently supported:
|
| systemName
| ----------
| To be able to open dialogs in the iframe, we need to specify the system path
| of the respective dialog. In order not having to remember this one every time,
| we simply map it once here
|
| mapArgs
| -------
| To have a more consistent API when dealing with dialogs, we decided to use
|
| ShellComponent
| --------------
| Shell dialog component to be rendered when `AVM.shell.dialogs.*.open(props)`
| is called.
|
*/

import AddTrustLedger from 'features/listings/dialogs/add-trust-ledger';
import React, { lazy } from 'react';
import { ErrorDialog } from 'src/components/error-dialog';

const ProcessLeadComponent = lazy(() =>
  import('features/leads/dialogs/process')
);

const ChangeLeadStatusComponent = lazy(() =>
  import('features/leads/dialogs/change-leads-status')
);

export default {
  // Shell Dialogs
  environment: {
    ShellComponent: lazy(() => import('view/dialogs/environment'))
  },

  insertMergeTags: {
    ShellComponent: lazy(() => import('view/components/dialog/merge-tags'))
  },

  portalConfirmUpload: {
    ShellComponent: lazy(() =>
      import('view/components/dialog/portals/confirm-upload')
    )
  },

  portalEditDelay: {
    ShellComponent: lazy(() =>
      import('view/components/dialog/portals/edit-delay')
    )
  },

  paywall: {
    ShellComponent: lazy(() => import('view/dialogs/paywall'))
  },

  manageHighlights: {
    ShellComponent: lazy(() =>
      import('view/dialogs/listings/manage-highlights')
    )
  },

  manageEpc: {
    ShellComponent: lazy(() => import('view/dialogs/listings/manage-epc'))
  },

  editRoom: {
    ShellComponent: lazy(() => import('view/dialogs/listings/edit-room'))
  },

  manageRooms: {
    ShellComponent: lazy(() => import('view/dialogs/listings/manage-rooms'))
  },

  filterFeedback: {
    ShellComponent: lazy(() => import('view/dialogs/listings/filter-feedback'))
  },

  filterFeedbackContacts: {
    ShellComponent: lazy(() =>
      import('view/dialogs/listings/filter-feedback-contacts')
    )
  },

  addMarketLead: {
    ShellComponent: lazy(() =>
      import('view/dialogs/market-leads/add-market-lead')
    )
  },

  confirmation: {
    ShellComponent: lazy(() => import('view/dialogs/confirmation'))
  },

  notice: {
    ShellComponent: lazy(() => import('view/dialogs/notice'))
  },

  icon: {
    ShellComponent: lazy(() => import('view/dialogs/icon'))
  },

  delete: {
    ShellComponent: lazy(() => import('view/dialogs/delete'))
  },

  deleteFromLibrary: {
    ShellComponent: lazy(() => import('view/dialogs/delete-from-library'))
  },

  addRelatedContact: {
    ShellComponent: lazy(() => import('view/dialogs/add-related-contact'))
  },

  editCustomValidation: {
    ShellComponent: lazy(() =>
      import('view/dialogs/custom-validation/edit-custom-validation')
    )
  },

  editEmbeddedApp: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/embedded-apps/edit-embedded-app')
    )
  },

  customValidationIssue: {
    ShellComponent: lazy(() =>
      import('view/dialogs/custom-validation/custom-validation-issue')
    )
  },

  addMarketLeadOrigin: {
    ShellComponent: lazy(() => import('view/dialogs/add-market-lead-origin'))
  },

  marketLeadAttachOrCreateRecord: {
    ShellComponent: lazy(() =>
      import('view/dialogs/market-leads/attach-or-create-record')
    )
  },

  marketLeadQualification: {
    ShellComponent: lazy(() =>
      import('view/dialogs/market-leads/lead-qualification')
    )
  },

  editIdType: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/identity/edit-id-type')
    )
  },

  editIdTypeRequirement: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/identity/edit-id-type-requirement')
    )
  },

  editIdTypeRequirementName: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/identity/edit-id-type-requirement-name')
    )
  },

  addressLookup: {
    ShellComponent: lazy(() => import('view/dialogs/address-lookup'))
  },

  addressLookupByPostcode: {
    ShellComponent: lazy(() => import('view/dialogs/postcode-address-lookup'))
  },

  imageCyclingSettings: {
    ShellComponent: lazy(() =>
      import('view/dialogs/listings/image-cycling/settings')
    )
  },

  imageCyclingChangeImageNow: {
    ShellComponent: lazy(() =>
      import('view/dialogs/listings/image-cycling/change-image-now')
    )
  },

  addOffer: {
    ShellComponent: require('features/listings/dialogs').AddOfferDialog
  },

  // TODO: rename this correctly
  editContract: {
    ShellComponent: require('features/listings/dialogs').ViewEditOfferDialog
  },

  changeOfferStatus: {
    ShellComponent: require('src/features/listings/dialogs').OfferStatusDialog
  },

  offerFallenDialog: {
    ShellComponent: require('features/listings/dialogs').OfferFallenDialog
  },

  offerMailMerge: {
    ShellComponent: require('features/listings/dialogs').OfferMailMergeDialog
  },

  archiveMarketLead: {
    ShellComponent: lazy(() => import('view/dialogs/market-leads/archive-lead'))
  },

  assignMarketLead: {
    ShellComponent: lazy(() => import('view/dialogs/market-leads/assign-lead'))
  },

  editCampaign: {
    ShellComponent: lazy(() => import('view/dialogs/campaigns/edit'))
  },

  addCampaignMembers: {
    ShellComponent: lazy(() => import('view/dialogs/campaigns/add-members'))
  },

  changeCampaignType: {
    ShellComponent: lazy(() =>
      import('view/dialogs/campaigns/change-campaign-type')
    )
  },

  campaignRecordActivityLog: {
    ShellComponent: lazy(() =>
      import('view/dialogs/campaigns/members/activity-log')
    )
  },

  generateCampaignAgenda: {
    ShellComponent: lazy(() => import('view/dialogs/campaigns/generate-agenda'))
  },

  createCampaignLog: {
    ShellComponent: lazy(() =>
      import('view/dialogs/campaigns/members/create-activity-log')
    )
  },

  updateCampaignMemberStatus: {
    ShellComponent: lazy(() =>
      import('view/dialogs/campaigns/members/update-status')
    )
  },

  campaignHistory: {
    ShellComponent: lazy(() =>
      import('view/dialogs/campaigns/members/campaign-history')
    )
  },

  copyRecordsToCampaign: {
    ShellComponent: lazy(() =>
      import('view/dialogs/campaigns/copy-records-to-campaign')
    )
  },

  campaignActivityLog: {
    ShellComponent: lazy(() =>
      import('view/dialogs/campaigns/members/activity-log')
    )
  },

  addEditWorkflow: {
    ShellComponent: lazy(() => import('view/dialogs/admin/workflow/add-edit'))
  },

  startSelectWorkflow: {
    ShellComponent: require('view/dialogs/workflow').StartSelectWorkflowDialog
  },

  confirmWorkflow: {
    ShellComponent: require('view/dialogs/workflow').ConfirmWorkflowDialog
  },

  viewWorkflow: {
    ShellComponent: require('view/dialogs/workflow').WorkflowDetailsDialog
  },

  endWorkflow: {
    ShellComponent: require('view/dialogs/workflow').EndWorkflowDialog
  },

  deleteWorkflow: {
    ShellComponent: lazy(() => import('view/dialogs/admin/workflow/delete'))
  },

  archiveCampaign: {
    ShellComponent: lazy(() => import('view/dialogs/campaigns/archive'))
  },

  editCampaignTemplate: {
    ShellComponent: lazy(() => import('view/dialogs/campaigns/edit-template'))
  },

  commissionsAddEditTierDialog: {
    ShellComponent: lazy(() =>
      import('view/dialogs/comissions/add-edit-commission-tier')
    )
  },

  commissionsPreviewDialog: {
    ShellComponent: lazy(() =>
      import('view/dialogs/comissions/commission-preview/index')
    )
  },

  amlCheck: {
    ShellComponent: lazy(() => import('view/dialogs/verifications/aml'))
  },

  editSubstantiation: {
    ShellComponent: lazy(() => import('view/dialogs/substantiations/edit'))
  },

  substantiationsList: {
    ShellComponent: lazy(() => import('view/dialogs/substantiations/list'))
  },

  editReferralCatergory: {
    ShellComponent: lazy(() => import('view/dialogs/referrals/edit-category'))
  },

  referralsList: {
    ShellComponent: lazy(() => import('view/dialogs/referrals/list'))
  },

  changeReferralStatus: {
    ShellComponent: lazy(() => import('view/dialogs/referrals/update-status'))
  },

  editListingCustomId: {
    ShellComponent: lazy(() => import('view/dialogs/edit-listing-custom-id'))
  },

  /**
   * Address and Phone Number Washing
   */
  updateWashingStatus: {
    ShellComponent: require('view/dialogs/phone-address-washing')
      .UpdateStatusDialog
  },

  reviewMailWashingContacts: {
    ShellComponent: require('view/dialogs/phone-address-washing')
      .ReviewMPSContactsDialog
  },

  washingRegistryWarning: {
    ShellComponent: require('view/dialogs/phone-address-washing')
      .RegistryWarningDialog
  },

  publishListing: {
    ShellComponent: lazy(() => import('view/dialogs/listings/publish-listing'))
  },

  addRequiredDocumentType: {
    ShellComponent: lazy(() => import('view/dialogs/add-required-docs'))
  },

  overwriteWarning: {
    ShellComponent: require('view/dialogs/collision-avoidance').OverwriteWarning
  },

  standardError: {
    ShellComponent: require('src/components/error-dialog').ErrorDialog
  },

  idVerification: {
    ShellComponent: lazy(() =>
      import('view/dialogs/verifications/id-verification')
    )
  },

  editNonInteractiveField: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/custom-tabs/edit-non-interactive-field')
    )
  },

  manageTabOrder: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/custom-tabs/manage-tab-order')
    )
  },

  addChain: {
    ShellComponent: lazy(() => import('features/chains/dialogs/add'))
  },

  editChain: {
    ShellComponent: lazy(() => import('features/chains/dialogs/edit'))
  },

  addChainSplit: {
    ShellComponent: lazy(() =>
      import('src/features/chains/dialogs/add-chain-split')
    )
  },

  editChainLink: {
    ShellComponent: lazy(() =>
      import('features/chains/dialogs/edit-chain-link')
    )
  },

  archiveChain: {
    ShellComponent: lazy(() => import('features/chains/dialogs/archive'))
  },

  addToChain: {
    ShellComponent: require('features/listings/dialogs').AddToChainDialog
  },

  manageCustomGroupDisplay: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/custom-tabs/manage-group-display')
    )
  },

  manageGroupPrivileges: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/custom-tabs/manage-group-privileges')
    )
  },

  AddLinkDialog: {
    ShellComponent: require('features/listings/dialogs').AddMarketingLinkDialog
  },

  premiumPortalSettings: {
    ShellComponent: lazy(() =>
      import('view/dialogs/listings/premium-portal-settings')
    )
  },

  /**
   * Calendar
   */

  editAppointment: {
    ShellComponent: lazy(() =>
      import('features/calendar/dialogs/edit-appointment')
    )
  },

  editAppointmentType: {
    ShellComponent: lazy(() =>
      import('features/calendar/dialogs/edit-appointment-type')
    )
  },

  changeEventOrganiser: {
    ShellComponent: lazy(() => import('view/dialogs/calendar/change-organiser'))
  },

  editRecurring: {
    ShellComponent: lazy(() => import('view/dialogs/calendar/edit-recurring'))
  },

  cancelAppointment: {
    ShellComponent: lazy(() =>
      import('view/dialogs/calendar/cancel-appointment')
    )
  },

  deleteAppointment: {
    ShellComponent: lazy(() =>
      import('view/dialogs/calendar/delete-appointment')
    )
  },

  addAppraisal: {
    ShellComponent: lazy(() => import('view/dialogs/calendar/add-appraisal'))
  },

  sendConfirmationMessages: {
    ShellComponent: lazy(() =>
      import(
        'features/calendar/dialogs/send-confirmations/send-confirmation-messages'
      )
    )
  },

  /**
   * Print integration
   */

  selectMailingService: {
    ShellComponent: lazy(() =>
      import('view/dialogs/print-integration/select-mailing-service')
    )
  },

  /**
   * Metric Write Rules
   */
  editMetricWriteRule: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/metric-write-rules/edit')
    )
  },

  editMetric: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/metric-write-rules/edit-metric')
    )
  },

  metricHistory: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/metric-write-rules/metric-history')
    )
  },

  metricInput: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/metric-write-rules/metric-input')
    )
  },

  addEditDisclaimer: {
    ShellComponent: lazy(() => import('view/dialogs/admin/disclaimer/edit'))
  },

  addBuilding: {
    ShellComponent: lazy(() => import('view/dialogs/building/create'))
  },

  /**
   * Click to Dial
   */

  addClickToDialUserInput: {
    ShellComponent: lazy(() =>
      import('view/dialogs/click-to-dial/add-user-input')
    )
  },

  makeCall: {
    ShellComponent: lazy(() => import('view/dialogs/click-to-dial/make-call'))
  },

  /**
   * Custom Relationship Categories
   */
  addEditCustomRelationshipCategory: {
    ShellComponent: lazy(() =>
      import(
        'shell/src/view/dialogs/admin/custom-relationships/edit-custom-relationship'
      )
    )
  },

  /**
   * Esign
   */
  enableEsignDocumentTemplate: {
    ShellComponent: lazy(() =>
      import('shell/src/view/dialogs/esign/enable-document-template')
    )
  },

  editEsignTemplateField: {
    ShellComponent: lazy(() =>
      import('shell/src/view/dialogs/esign/template-field')
    )
  },

  esignRequestSignature: {
    ShellComponent: lazy(() =>
      import('shell/src/view/dialogs/esign/request-signature/core')
    )
  },

  editTemplateName: {
    ShellComponent: lazy(() => import('view/dialogs/edit-template-name'))
  },

  /**
   * Scheduled messages
   */

  scheduleNewsletter: {
    ShellComponent: lazy(() =>
      import('view/dialogs/scheduled-messages/schedule-newsletter')
    )
  },

  scheduleMail: {
    ShellComponent: lazy(() =>
      import('src/view/dialogs/scheduled-messages/schedule-mail')
    )
  },

  scheduleReminder: {
    ShellComponent: lazy(() =>
      import('src/view/dialogs/scheduled-messages/schedule-reminder')
    )
  },

  autosendError: {
    ShellComponent: lazy(() =>
      import('src/view/dialogs/scheduled-messages/autosend-error.tsx')
    )
  },

  /**
   * Key register
   */

  editKey: {
    ShellComponent: lazy(() => import('view/dialogs/key-registry/edit-key'))
  },
  checkOutKey: {
    ShellComponent: lazy(() =>
      import('view/dialogs/key-registry/check-out-key')
    )
  },
  checkInKey: {
    ShellComponent: lazy(() => import('view/dialogs/key-registry/check-in-key'))
  },
  keyHistory: {
    ShellComponent: lazy(() => import('view/dialogs/key-registry/history'))
  },
  editKeyLocation: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/key-registry/edit-key-location')
    )
  },
  relocateKeys: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/key-registry/relocate-keys')
    )
  },

  editFeatureConsentAndActions: {
    ShellComponent: lazy(() =>
      import('view/dialogs/admin/privacy/edit-feature-consent-and-actions')
    )
  },
  editRecipients: {
    ShellComponent: lazy(() => import('view/dialogs/privacy/edit-recipients'))
  },

  userSegmentation: {
    ShellComponent: lazy(() => import('view/dialogs/user-segmentation'))
  },

  bulkArchiveLeads: {
    ShellComponent: lazy(() =>
      import('features/leads/dialogs/bulk-archive-leads')
    )
  },

  processLead: {
    ShellComponent: ({ refreshList, ...props }) => {
      const refreshListOverride = () => {
        window.Rex2FrameWindow?.AVM.events.trigger('leads.update');
        refreshList?.();
      };
      return (
        <ProcessLeadComponent refreshList={refreshListOverride} {...props} />
      );
    }
  },

  addTrustLedger: {
    ShellComponent: (props) => {
      return <AddTrustLedger {...props} />;
    }
  },

  changeLeadStatus: {
    ShellComponent: (props) => {
      return <ChangeLeadStatusComponent {...props} />;
    }
  },

  reassignToParentAccount: {
    ShellComponent: lazy(() =>
      import('features/leads/dialogs/reassign-to-parent-account')
    )
  },

  reassignToSubAccount: {
    ShellComponent: lazy(() =>
      import('features/leads/dialogs/reassign-to-sub-account')
    )
  },

  // Note that while other dialogs are lazily imported, ErrorDialog is commonly called, critical in nature, and small, so it warrants importing directly.
  errorDialog: { ShellComponent: (props) => <ErrorDialog {...props} /> },

  sendSalesMemo: {
    ShellComponent: lazy(() =>
      import('features/contracts/dialogs/send-sales-memo-dialog')
    )
  },

  generateAdText: {
    ShellComponent: lazy(() => import('features/ai/dialogs/generate-ad-text'))
  },

  generateCommunication: {
    ShellComponent: lazy(() =>
      import('features/ai/dialogs/generate-communication')
    )
  },

  customReports: {
    ShellComponent: lazy(() =>
      import('features/custom-reporting/dialogs/custom-report-dialog')
    )
  },

  editCustomReport: {
    ShellComponent: lazy(() =>
      import('features/custom-reporting/dialogs/edit-custom-report')
    )
  },

  addCustomReport: {
    ShellComponent: lazy(() =>
      import('features/custom-reporting/dialogs/add-custom-report')
    )
  },

  updateChildAccountSetting: {
    ShellComponent: lazy(() =>
      import('features/settings/dialogs/update-child-account-setting')
    )
  },
  disconnectConnection: {
    ShellComponent: lazy(() =>
      import('features/settings/dialogs/disconnect-connection')
    )
  },
  markConnectionType: {
    ShellComponent: lazy(() =>
      import('features/settings/dialogs/mark-connection-type')
    )
  },
  connectionToken: {
    ShellComponent: lazy(() =>
      import('features/settings/dialogs/connection-token')
    )
  },
  addCompany: {
    ShellComponent: lazy(() => import('view/dialogs/companies/add'))
  },
  addContact: {
    ShellComponent: lazy(() => import('view/dialogs/contacts/add'))
  },
  createBrochure: {
    ShellComponent: lazy(() =>
      import('features/settings/dialogs/create-brochure')
    )
  },
  addEditApplication: {
    ShellComponent: lazy(() =>
      import(
        'features/tenancy-applications/dialogs/application-dialog/index.tsx'
      )
    )
  },
  addEditTenant: {
    ShellComponent: lazy(() =>
      import('features/tenancy-applications/dialogs/add-edit-tenant/index.tsx')
    )
  },
  addEditAppraisal: {
    ShellComponent: lazy(() =>
      import('features/appraisals/dialogs/appraisal-add-edit.tsx')
    )
  },
  archiveAppraisal: {
    ShellComponent: lazy(() =>
      import('features/appraisals/dialogs/appraisal-archive.tsx')
    )
  },
  updateApplicationStatus: {
    ShellComponent: lazy(() =>
      import(
        'features/tenancy-applications/dialogs/update-status-dialog/index.tsx'
      )
    )
  },
  viewFeedUrl: {
    ShellComponent: lazy(() =>
      import('features/portals/dialogs/view-feed-url.tsx')
    )
  },
  editTenancyOffer: {
    ShellComponent: lazy(() =>
      import('features/tenancy-applications/dialogs/edit-tenancy-offer')
    )
  },
  recordTenancyOffer: {
    ShellComponent: lazy(() =>
      import('features/tenancy-applications/dialogs/record-offer')
    )
  },
  deleteApplication: {
    ShellComponent: lazy(() =>
      import(
        'features/tenancy-applications/dialogs/application-delete/index.tsx'
      )
    )
  },
  markAsFailedApplication: {
    ShellComponent: lazy(() =>
      import('features/tenancy-applications/dialogs/mark-as-failed/index.tsx')
    )
  },
  conditionDialog: {
    ShellComponent: lazy(() =>
      import('features/contracts/dialogs/condition-dialog')
    )
  },
  manageListingConditions: {
    ShellComponent: lazy(() =>
      import('features/listings/dialogs/manage-listing-conditions/index.tsx')
    )
  },
  manageConditions: {
    ShellComponent: lazy(() =>
      import('features/contracts/dialogs/manage-conditions/index.tsx')
    )
  },
  addCondition: {
    ShellComponent: lazy(() =>
      import('features/contracts/dialogs/add-condition/index.tsx')
    )
  },
  editCondition: {
    ShellComponent: lazy(() =>
      import('features/contracts/dialogs/edit-condition/index.tsx')
    )
  },
  addEditPipelineStage: {
    ShellComponent: lazy(() =>
      import('features/pipelines/dialogs/add-edit-pipeline-stage.tsx')
    )
  },
  manageLink: {
    ShellComponent: lazy(() =>
      import('features/hand-off/dialogs/manage-link/index.tsx')
    )
  },
  removeLink: {
    ShellComponent: lazy(() =>
      import('features/hand-off/dialogs/remove-link/index.tsx')
    )
  },
  handoffRexPM: {
    ShellComponent: lazy(() => import('view/dialogs/handoff-rex-pm'))
  },
  handoffDetails: {
    ShellComponent: lazy(() =>
      import('view/dialogs/handoff-rex-pm/handoff-details')
    )
  },
  selectDocuments: {
    ShellComponent: lazy(() =>
      import('features/hand-off/dialogs/select-documents/index.tsx')
    )
  },
  editReinzForm: {
    ShellComponent: lazy(() =>
      import('features/reinz-forms/dialogs/edit-reinz-form')
    )
  },
  manageReinzForms: {
    ShellComponent: lazy(() =>
      import('features/reinz-forms/dialogs/manage-reinz-forms/index.tsx')
    )
  },
  createReinzForm: {
    ShellComponent: lazy(() =>
      import('features/reinz-forms/dialogs/create-reinz-form/index.tsx')
    )
  },
  addEditReinzCustomValue: {
    ShellComponent: lazy(() =>
      import('features/apps/reinz/dialogs/add-edit-custom-value/index.tsx')
    )
  },
  upsertChartOfAccount: {
    ShellComponent: lazy(() =>
      import('features/finance/dialogs/upsert-chart-of-account')
    )
  },
  referralBillSelector: {
    ShellComponent: lazy(() =>
      import('features/listings/dialogs/referral-bill-selector')
    )
  },
  addSlidingScaleSetting: {
    ShellComponent: lazy(() =>
      import(
        'features/settings/components/sliding-scale-settings/dialogs/add-sliding-scale-setting/index.tsx'
      )
    )
  },
  internalApprovalDialog: {
    ShellComponent: lazy(() =>
      import('features/reminders/dialogs/internal-approval-dialog')
    )
  },
  lockWorksheetDialog: {
    ShellComponent: lazy(() =>
      import('features/commission-worksheet/dialogs/lock-worksheet-dialog')
    )
  },
  commissionBreakdown: {
    ShellComponent: lazy(() =>
      import('features/commission-worksheet/dialogs/commission-breakdown')
    )
  },
  saveWorksheetDialog: {
    ShellComponent: lazy(() =>
      import(
        'features/commission-worksheet/dialogs/save-worksheet-dialog/index.tsx'
      )
    )
  },
  addEditNewsletterTemplate: {
    ShellComponent: lazy(() =>
      import(
        'features/newsletter-builder/dialogs/add-edit-newsletter-template.tsx'
      )
    )
  },
  addEditNewsletter: {
    ShellComponent: lazy(() =>
      import('features/newsletter-builder/dialogs/add-edit-newsletter.tsx')
    )
  },
  theLettingsHub: {
    ShellComponent: lazy(() =>
      import(
        'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog'
      )
    )
  },
  theLettingsHubSyncApplicant: {
    ShellComponent: lazy(() =>
      import('features/the-lettings-hub/dialogs/lettings-hub-sync')
    )
  }
};
